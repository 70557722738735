<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="기준년도"
            name="measureYear"
            type="year"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col1Items"
            itemText="codeName"
            itemValue="code"
            label="배출구분"
            name="col1"
            v-model="searchParam.col1">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col2Items"
            itemText="codeName"
            itemValue="code"
            label="배출시설 종류"
            name="col2"
            v-model="searchParam.col2">
          </c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="온실가스 배출시설 목록"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고정연소' },
        { code: '2', codeName: '이동연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
            fix: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '배출시설 코드',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
            fix: true,
          }, 
          {
            name: 'col3',
            field: 'col3',
            label: '배출시설 명',
            align: 'center',
            sortable: true,
            type: 'link',
            style: 'width: 250px',
            fix: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '소규모<br>배출시설',
            align: 'center',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
            style: 'width: 80px',
            fix: true,
          }, 
          {
            name: 'col01',
            field: 'col01',
            label: '신설/증설/폐쇄 등 정보',
            child: [
              {
                name: 'col5',
                field: 'col5',
                label: '구분',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              }, 
              {
                name: 'col7',
                field: 'col7',
                label: '발생일',
                align: 'center',
                sortable: true,
                style: 'width: 120px',
              }, 
              {
                name: 'col8',
                field: 'col8',
                label: '사유',
                align: 'center',
                sortable: true,
                style: 'width: 150px',
              }, 
            ]
          },
          {
            name: 'col9',
            field: 'col9',
            label: '할당대상 여부',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'col10',
            field: 'col10',
            label: '특이사항',
            align: 'center',
            style: 'width: 200px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col2: '0005', 
          col3: '공정 연소시설', 
          col4: 'N', 
          col5: '증설', 
          col7: '2022-04-21', 
          col8: '생산량증가', 
          col9: 'Y', 
          col10: '', 
        },
        {
          col1: '사업장1', 
          col2: '0021', 
          col3: '발전용 내연기관', 
          col4: 'N', 
          col5: '', 
          col7: '', 
          col8: '', 
          col9: 'Y', 
          col10: '', 
        },
        {
          col1: '사업장1', 
          col2: '0055', 
          col3: '일반 보일러시설', 
          col4: 'Y', 
          col5: '패쇄', 
          col7: '2022-04-21', 
          col8: '제품제외', 
          col9: 'Y', 
          col10: '', 
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./dischargeFacDetail.vue"}`);
      this.popupOptions.title = '온실가스 배출시설 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
